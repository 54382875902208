import React from "react";
import { graphql } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx";


export default function ArticleTemplate({
    data, // this prop will be injected by the GraphQL query below.
  }) {
    const { mdx } = data; // data.mdx holds your post data
    const { frontmatter, body } = mdx;
    return (
      <Layout>
        <SEO
          keywords={[`dayyan smith`, `software engineer`]}
          title={frontmatter.title}
        />
        <div className="mt-16 max-w-2xl mx-auto text-lg prose prose-sm">
            <h2>{frontmatter.title}</h2>
            <div className="text-gray-400">{frontmatter.date}</div>
            <MDXProvider>
                <MDXRenderer>
                    {body}
                </MDXRenderer>
            </MDXProvider>
        </div>
      </Layout>
    );
  }

  export const pageQuery = graphql`
    query($id: String!) {
      mdx(id: { eq: $id }) {
        body
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
          description
        }
      }
    }
  `;
  